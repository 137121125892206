/* Checkbox */
.custom-chekbox + span {
	cursor: pointer;
	position: relative;
	float: left;
	width: 100%;
	padding-left: 25px;
	text-align: left;
}

.custom-chekbox {
	position: relative;
	float: left;
	visibility: hidden;
}

.custom-chekbox + span:before {
	content: '';
	background-color: #fff;
	position: absolute;
	float: left;
	left: 0;
	width: 1.3rem;
	height: 1.3rem;
	border: solid 1px #eee;
	border-radius: 4px;
}

.custom-chekbox:checked + span:before {
	background-color: #467fcf;
}

.custom-chekbox + span:after {
	content: '';
	position: absolute;
	float: left;
	left: 0.15rem;
	top: 0.15rem;
	width: 1rem;
	height: 1rem;
}

.custom-chekbox:checked + span:after {
	background: url(../svg/check-normal.svg);
	background-size: 100%;
	background-repeat: no-repeat;
	transform: scale(0.7);
}

/*  select group */

.selectgroup-item input {
	/* visibility: hidden; */
	position: relative;
	float: left;
	top: -20px;
}

.selectgroup-item {
	flex-grow: 1;
	position: relative;
}

.selectgroup {
	display: inline-flex;
	width: 100%;
}

.selectgroup-input:checked + .selectgroup-button {
	border-color: #467fcf;
	z-index: 1;
	color: #467fcf;
	background: #edf2fa;
}

.selectgroup-button {
	display: block;
	border: 1px solid rgba(0, 40, 100, 0.12);
	text-align: center;
	padding: 0.375rem 1rem;
	position: relative;
	cursor: pointer;
	border-radius: 3px;
	color: #9aa0ac;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background: #fff;
	font-size: 0.9375rem;
	line-height: 1.5rem;
	min-width: 2.375rem;
}

.blue-cloud {
	background-color: #2abbdc !important;
	border-color: #2abbdc !important;
	color: #fff !important;
}

.blue-cloud:hover {
	background-color: #0b8aa7 !important;
	transform: translateY(-1px);
}

.checkbox_amenidades {
	/* flex-grow: 1; */
	width: 180px;
	height: 50px;
}

.amenidades {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-items: flex-start;
	align-self: flex-start;
}

.Content-Dropzone {
	margin-bottom: 20px;
	width: 100%;
	height: 120px;
	-webkit-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
	border: solid 1px #ddd;
	-webkit-box-shadow: 0 0 2px #eee;
	background-color: #fff;
	padding: 20px;
	border-radius: 5px;
	cursor: pointer;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
	border-left: 0px;
	border-right: 0px;
}

.dropzone-container {
	background-color: #f9f9f9;
	border-radius: 4px;
	box-shadow: 1px 1px 10px #d4d4d41a;
	border: 1px solid #e4e4e4;
	border-top: 0;
}
.dropzone,
.dropzone2 {
	padding: 30px;
	border: 1px solid #d8d8d8;
	border-style: dashed;
	text-align: center;
	font-size: 1rem;
	border-radius: 4px;
	margin-bottom: 20px;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.dropzone p,
.dropzone2 p {
	margin: 0;
}
.dropzone #info i,
.dropzone2 #info i {
	font-size: 2rem;
	opacity: 0.6;
}
.dropzone #info,
.dropzone2 #info {
	max-width: 300px;
	margin: 0 auto;
}

.designSelector {
	cursor: pointer;
	transition: all 0.3s;
	display: block;
	width: 100%;
	padding: 1rem;
	border: 1px solid #e8eaf0;
}

.designSelector span {
	pointer-events: none;
}

.designSelector:hover {
	color: #fff;
	background-color: #112a5f;
}

.designSelector img {
	width: 100%;
	pointer-events: none;
}

.designSelector.active,
.designSelector.active:hover {
	color: #fff;
	background-color: #031b4d;
	opacity: 1;
}

.designSelector-pop .bp3-popover-target {
	width: 100%;
}

.imagecheck {
	margin: 0;
	position: relative;
	cursor: pointer;
	width: 100%;
	height: 100%;
	transition: all 0.3s;
	border-radius: 4px;
}
.imagecheck:hover {
	box-shadow: 0 0 10px #00000057;
}
.imagecheck img {
	border-radius: 4px;
}

input[type='radio'],
input[type='checkbox'] {
	box-sizing: border-box;
	padding: 0;
}

.imagecheck-input {
	position: absolute;
	z-index: -1;
	opacity: 0;
}

.imagecheck-figure {
	border: 1px solid rgba(0, 40, 100, 0.12);
	margin: 0;
	position: relative;
	width: 100%;
	height: 100%;
}

.imagecheck-figure img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.imagecheck-input:checked ~ .imagecheck-figure {
	border-color: rgba(0, 40, 100, 0.24);
}

.imagecheck-image {
	opacity: 0.9;
}

.thumbnail {
	border-radius: 2px !important;
	min-width: 150px;
	width: 150px;
	height: 150px;
	list-style-type: none;
	display: inline-block;
	/* transition: all .01s; */
}

.thumbnail:hover {
	opacity: 0.8;
}
.content-gallery-dnd {
	display: flex;
	flex-flow: row nowrap;
	overflow-x: auto;
}
m footer,
section {
	position: relative;
	float: left;
	width: 100%;
}

.dropdown-item {
	cursor: pointer;
}

.btn-search {
	float: right;
}

/* SectionHero.JS */

.text-box-center {
	text-align: center;
	color: #fff;
	display: block;
	width: 100%;
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

/* Property Card */

.card__property-description {
	padding: 0.75rem;
}

.card__property-title {
	font-weight: 700;
	color: #06b1d7;
}

.card__property-price {
	font-size: 1.2rem;
	font-weight: 700;
	display: inline-block;
}

.card__property-listingType {
	text-transform: uppercase;
	letter-spacing: 2px;
	display: inline-block;
	padding-left: 1rem;
	font-size: 0.7rem;
}

.card__property-location {
	font-size: 0.9rem;
	opacity: 0.6;
}

/* .card__property-ammenities {
    display: inline-block;
    font-size: .9rem;
    opacity: .6;
}
.card__property-ammenities-item {
    margin-left: 15px;
    display: inline-block;
}

.card__property-ammenities-item:first-child {
    margin-left: 0px;
} */

.card__property-ammenities ul {
	list-style: none;
	margin-left: 0;
	padding-left: 0;
	display: block;
	margin-top: 5px;
	margin-bottom: 0;
}

.card__property-ammenities ul li {
	display: inline-block;
	margin-right: 0.8rem;
	text-align: center;
}

/* Navigation Tabbar for Blog */

.nav-tabs {
	margin-top: -0.35rem;
}

.nav-tabs > .nav-item > .nav-link:hover {
	color: #06b1d7 !important;
	border-bottom: 4px solid #06b1d7 !important;
	background-color: transparent;
	border: none;
}

.nav-tabs > .nav-link:focus {
	border: none;
}

.nav-tabs > .nav-link:after {
	border: none;
}

.nav-tabs > .nav-tabs .nav-link:hover:not(.disabled) {
	border-color: transparent;
	transform: translateY(1.9px);
}

.nav-tabs > .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
	border-color: #dee2e6 #dee2e6 #fff;
	color: #06b1d7 !important;
	border-bottom: 4px solid #06b1d7 !important;
	background-color: transparent;
	border: none;
}

.nav-tabs > .nav-item:first-child {
	margin-left: 1.5rem;
}

/* BlogPost */

.card-post {
	border: none;
	box-shadow: none;
}

/*  Home Cards  */

.homeCards {
	border-radius: 5px;
	transition: all 0.3s;
}

.homeCards:hover {
	box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
	transform: translateY(-5px);
}

.homeCards--container {
	/* background-image: linear-gradient(to right bottom, rgba(0, 5, 8, 0.5), rgba(2, 0, 94, 0.5)), url(http://www.lacharettecreole.com/file/2018/06/getting_the_luxury_custom_home_of_your_dreams.jpg); */
	width: 100%;
	height: 280px;
	background-size: cover;
	border-radius: 5px;
}

.homeCards--overlay {
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-transform: uppercase;
	color: #fff;
	font-weight: 700;
}

/* Button Filter */

.btnFilter {
	background-color: #ffffff;
	-webkit-appearance: inherit;
	width: 33.33%;
	padding: 10px;
	text-transform: uppercase;
	color: #6b6b6b;
	transition: all 0.3s;
	cursor: pointer;
}

.btnFilter:first-child {
	border-top-right-radleft-radius: 5px;
	border-bottom-right-radius: 2px;
	adiusbo: 5px;
	border-right: none;
}

.btnFilter:last-child {
	border-left: none;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}

.btnFilter:focus {
	outline: 0;
}

.btnFilter:hover {
	background-color: #06b1d7;
	color: #fff;
	border-color: #06b1d7;
}

.btnFilter.active {
	background-color: #06b1d7;
	color: #fff;
	border-color: #06b1d7;
}

.btnFilter:active {
	background-color: rgb(1, 154, 189);
	border: 1px solid rgb(1, 154, 189);
	/* border: none; */
}

.about_property ul li:nth-child(1),
.about_property ul li:nth-child(2),
.about_property ul li:nth-child(3) {
	border-bottom: 1px solid #e1e6ec;
}

.about_property ul li:last-child,
.about_property ul li:nth-child(3) {
	border-right: none;
}

.about_property ul li {
	border-right: 1px solid #e1e6ec;
}

.about_property ul {
	margin: 0px;
	padding: 0px;
	list-style: none;
	width: 550px;
	margin: auto;
}

.about_property ul li {
	display: inline-block;
	height: 185px;
	text-align: center;
	width: 180px;
	padding-top: 3rem;
}

@media (max-width: 992px) {
	.about_property ul li {
		border: 1px solid #e1e6ec;
	}
	.about_property ul li:last-child,
	.about_property ul li:nth-child(3) {
		border: 1px solid #e1e6ec;
	}
	.about_property {
		text-align: center;
	}
}

#ubicacion {
	background-color: #009edd;
	color: #fff;
}

.filter-container {
	min-width: 350px;
	max-width: 500px;
	position: absolute;
	z-index: 10;
	border: 0px solid #3e7ed3;
	background: #fff;
	padding: 10px 20px;
	margin-top: 10px;
	border-radius: 5px;
	border-top: 0px;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	box-shadow: 0px 7px 10px #00000014;
}

.property-card {
	transition: all 0.3s;
}

.property-card:hover {
	box-shadow: 0px 0px 10px rgba(131, 131, 131, 0.212);
}

/*==================================
=      ACTIVITY
====================================*/

.activity-container {
	padding-left: 0px !important;
	padding-right: 0px !important;
	padding-top: 1.2rem;
	padding-bottom: 1.2rem;
	border: 1px solid rgba(0, 40, 100, 0.12);
}

.activity-actions {
	border: 1px solid rgba(0, 40, 100, 0.12);
	padding: 5px;
	border-bottom: 0;
}

.activity h5 {
	margin-bottom: 0 !important;
}
.activity small,
.activity small a {
	color: #888 !important;
}

.activity small i {
	opacity: 0.4;
	margin-right: 3px;
	margin-left: 5px;
}

.activity small a {
	color: #495057;
}

.activity-separator {
	margin: 0;
	padding: 0 5px;
	width: 10px;
	display: inline-block;
	cursor: default;
	text-align: center;
}

.media-heading.activity {
	margin-bottom: 0.7rem;
}

/*==================================
=      CHECKBOX
====================================*/

.md-checkbox {
	position: relative;
	/* margin: 1em 0; */
	text-align: left;
}

.md-checkbox label {
	margin-bottom: 0px !important;
}

.md-checkbox.md-checkbox-inline {
	display: inline-block;
}

.md-checkbox label {
	cursor: pointer;
	display: inline;
	line-height: 1.25em;
	vertical-align: top;
	clear: both;
	padding-left: 1px;
}

.md-checkbox label:not(:empty) {
	padding-left: 0.75em;
}
.md-checkbox label:before,
.md-checkbox label:after {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
}

.md-checkbox label:before {
	width: 1.25em;
	height: 1.25em;
	background: #fff;
	border: 1px solid rgba(0, 0, 0, 0.54);
	border-radius: 50%;
	cursor: pointer;
	transition: background 0.3s;
}

.md-checkbox label:hover:before {
	transition: all 0.3s;
	border: 2px solid #09963c;
}

.md-checkbox label:before:hover {
	border: 1px solid red;
}
.md-checkbox input[type='checkbox'] {
	outline: 0;
	visibility: hidden;
	width: 1.25em;
	margin: 0;
	display: block;
	float: left;
	font-size: inherit;
}
.md-checkbox input[type='checkbox']:checked + label:before {
	background: #08a742;
	border: none;
}
.md-checkbox input[type='checkbox']:checked + label:after {
	transform: translate(0.25em, 0.33654em) rotate(-45deg);
	width: 0.75em;
	height: 0.375em;
	border: 0.125em solid #fff;
	border-top-style: none;
	border-right-style: none;
}
.md-checkbox input[type='checkbox']:disabled + label:before {
	border-color: rgba(0, 0, 0, 0.26);
}
.md-checkbox input[type='checkbox']:disabled:checked + label:before {
	background: rgba(0, 0, 0, 0.26);
}
.md-checkbox input,
.md-checkbox label {
	cursor: pointer !important;
}

/*==================================
=      DEALS
====================================*/

.deal__name--contact {
	margin-right: 10px;
}

.deal__budget {
	font-size: 1rem;
	font-weight: 600;
}

.deal__budget:first-child {
	margin-right: 1rem;
}

.deal__budget:nth-child(2) {
	margin-right: 1.2rem;
}

.deal__name ul {
	padding-left: 0;
	list-style-type: none;
}

.deal__name ul li {
	display: inline-block;
}

.deal__btns {
	float: right;
}

.deal__btns button {
	margin-right: 0.8rem;
}

.deal__btns button:last-child {
	margin-right: 0rem;
}

.deal__stage--list {
	width: 100%;
}

.deal__stage--stages {
	opacity: 0.5;
}

ul.deal__stage--list {
	padding-left: 0;
	list-style: none;
	display: flex;
	justify-content: space-evenly;
	width: 100%;
}

li.deal__stage--item {
	cursor: pointer;
	display: inline-block;
	background: #e5e5e5;
	width: 100%;
	color: #fff;
	text-align: center;
	margin-left: 2px;
}

li.deal__stage--item.active {
	background: #08a742;
}

li.deal__stage--item:first-child {
	margin-left: 0px;
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
}

li.deal__stage--item:last-child {
	border-top-right-radius: 2px;
	border-bottom-right-radius: 2px;
}

.deal__stage--list span.bp3-popover-target {
	width: 100% !important;
}

span.bp3-popover-target {
	display: inline-block;
}

.btn-control {
	padding: 3px 15px !important;
	font-size: 1rem !important;
}

.btn-control .bp3-spinner {
	padding: 2px 1rem;
}

.whiteSpinner svg path:last-child {
	stroke: rgb(255, 255, 255) !important;
}

.btn-green {
	box-shadow: 0 1px 2px rgba(38, 41, 44, 0.08);
}

.btn-red {
	color: #fff;
	background-color: #e42b2a;
	border-color: #da0604;
	box-shadow: 0 1px 2px rgba(38, 41, 44, 0.08);
}

.btn-white {
	background-color: #fff;
	border-color: #eee;
	box-shadow: 0 1px 2px rgba(38, 41, 44, 0.08);
}

.btn-lightgrey {
	background-color: #fff;
	box-shadow: inset 0 0 0 1px #cbcccd, 0 1px 2px rgba(38, 41, 44, 0.08);
	padding: 3px 15px !important;
}

.stroke-white path:first-child {
	stroke: rgba(255, 255, 255, 0.2);
}

.stroke-white path:last-child {
	stroke: #fff !important;
}

.deal__action {
	margin-bottom: 3px;
	margin-left: 1rem;
	display: inline-block;
}

.won-badge,
.lost-badge {
	cursor: pointer;
	padding: 8px 20px;
	border-radius: 20px;
	text-transform: uppercase;
	color: #fff;
	font-weight: 600;
}

.won-badge {
	background-color: #08a742;
}

.lost-badge {
	background-color: #e42b2a;
}

.won-badge-sm {
	margin-top: 2px;
	border-radius: 2px;
	text-transform: uppercase;
	color: #fff;
	font-weight: 600;
	background-color: #08a742;
	font-size: 12px;
	padding: 2px 8px;
}

/*==================================
=      COMPLETED
====================================*/
.strike {
	text-decoration: line-through !important;
	color: #747678 !important;
}

/*==================================
=      DRAWER QUICK-VIEW
====================================*/

.quickv__title {
	background: linear-gradient(90deg, #031b4d, #0e3381);
	color: #fff;
	display: flex;
	padding: 1rem 1rem 0.8rem 1rem;
}

.quickv__title--h4 {
	width: 85%;
	display: flex;
	align-items: center;
	padding-top: 8px;
}

.quickv__close {
	width: 15%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.deal-card {
	border: 1px solid #000;
	border-radius: 2px;
}

.yellow-bg {
	background-color: #fffcdc !important;
}

/*==================================
=      FUNNEL DETAILS
====================================*/

.pipelineCanvas {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	display: flex;
}

.nodeco {
	text-decoration: none !important;
}

.deal {
	padding: 7px 10px;
	transition: all 0.3s;
}

.deal:hover {
	box-shadow: 0px 0px 8px #00000026;
}
.stageBorder {
	border: 1px solid transparent;
	padding: 3px;
}

.stageBorder.hover {
	/* border-color: #eee; */
	background-color: #eee;
	height: 100vh;
}

.question-mark {
	opacity: 0.3;
}

.pipelineSummary .pipelineStages ul {
	width: 100%;
	display: table;
	border-collapse: collapse;
	table-layout: fixed;
	height: 24px;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
	box-sizing: border-box;
	overflow: hidden;
}

.pipelineSummary .pipelineStages ul li {
	position: relative;
	background: #e5e5e5;
	display: table-cell;
	text-align: left;
	line-height: 24px;
	vertical-align: middle;
	color: #fff;
	text-align: center;
	cursor: pointer;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.pipelineSummary .pipelineStages ul li .stageContent {
	position: relative;
}

.pipelineSummary .pipelineStages ul li .stageContent .stagename {
	font: 400 12px/16px Open Sans, sans-serif;
	display: inline-block;
	position: relative;
	left: 4px;
}

.pipelineSummary .pipelineStages ul li .stageContent .stageArrow {
	width: 6px;
	height: 30px;
	position: absolute;
	top: -6px;
	left: 0;
	overflow: hidden;
	pointer-events: none;
	/* AQUI QUIZAS */
}

.pipelineSummary .pipelineStages ul li .stageContent .stageArrow:after {
	width: 25px;
	height: 22px;
	position: absolute;
	right: -7px;
	top: 7px;
	border-top: 3px solid #fff;
	border-right: 3px solid #fff;
	-webkit-transform: scaleX(0.3) rotate(45deg);
	transform: scaleX(0.3) rotate(45deg);
	content: ' ';
	cursor: pointer;
	background: #e5e5e5;
	pointer-events: none;
	/* AQUI QUIZAS */
}

.pipelineSummary .pipelineStages ul li:first-child {
	-moz-border-top-left-radius: 4px;
	border-top-left-radius: 4px;
	-moz-border-bottom-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

.pipelineSummary .pipelineStages ul li:last-child {
	-moz-border-top-right-radius: 4px;
	border-top-right-radius: 4px;
	-moz-border-bottom-right-radius: 4px;
	border-bottom-right-radius: 4px;
}

.pipelineSummary .pipelineStages ul li:first-child .stageContent:before {
	display: none;
}

.pipelineSummary .pipelineStages ul li:first-child .stageContent .stageArrow {
	display: none;
	/* pointer-events: none; */
}
.pipelineSummary .pipelineStages.open ul li.active,
.pipelineSummary .pipelineStages.open ul li.active .stageContent .stageArrow:after,
.pipelineSummary .pipelineStages.won ul li.active,
.pipelineSummary .pipelineStages.won ul li.active .stageContent .stageArrow:after {
	background: #08a742;
	/* pointer-events: none; */
}
.pipelineSummary .pipelineStages.open ul li.active + li .stageContent .stageArrow:after,
.pipelineSummary .pipelineStages.won ul li.active + li .stageContent .stageArrow:after {
	border-top: 3px solid #fff;
	border-right: 3px solid #fff;
	background: #08a742;
	/* pointer-events: none; */
}

.input .stages .stageOptionWrapper label:first-child {
	padding-left: 3px;
	-moz-border-top-left-radius: 4px;
	border-top-left-radius: 4px;
	-moz-border-bottom-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

.input .stages .stageOptionWrapper label {
	height: 28px;
	cursor: pointer;
	display: table-cell;
	text-align: center;
	padding: 0;
	font-size: 12px;
	font-weight: 700;
	color: #fff;
	background: #08a742;
	position: relative;
}

.input .stages .stageOptionWrapper label:before {
	width: 20px;
	height: 24px;
	position: absolute;
	right: -11px;
	top: 2px;
	border-top: 3px solid #fff;
	border-right: 3px solid #fff;
	transform: scaleX(0.3) rotate(45deg);
	content: ' ';
	background: #08a742;
	cursor: pointer;
	z-index: 1;
}
.input .stages .stageOptionWrapper label.active,
.input .stages .stageOptionWrapper label.active:before {
	background: #08a742;
}

.input .stages .stageOptionWrapper label {
	height: 28px;
	cursor: pointer;
	display: table-cell;
	text-align: center;
	padding: 0;
	font-size: 12px;
	font-weight: 700;
	color: #fff;
	background: #08a742;
	position: relative;
}
.input .stages .stageOptionWrapper label.active,
.input .stages .stageOptionWrapper label.active:before {
	background: #08a742;
}

.input .stages .stageOptionWrapper label:before {
	width: 20px;
	height: 24px;
	position: absolute;
	right: -11px;
	top: 2px;
	border-top: 3px solid #fff;
	border-right: 3px solid #fff;
	transform: scaleX(0.3) rotate(45deg);
	content: ' ';
	background: #08a742;
	cursor: pointer;
	z-index: 1;
}

.input .stages .options {
	display: table;
	width: 100%;
	overflow: hidden;
	margin-bottom: 4px;
	border-radius: 4px;
	table-layout: fixed;
}

.input .stages .stageOptionWrapper label input {
	width: auto;
	position: absolute;
	opacity: 0;
	cursor: pointer;
}

.Content-Dropzone-Media {
	width: 100% !important;
	height: 100vh !important;
	position: fixed !important;
	z-index: 9999 !important;
	background: rgba(10, 121, 212, 0.795) !important;
	top: 0;
	left: 0;
	display: grid;
	grid-template-columns: 30% 40% 30%;
	grid-template-rows: 30% 40% 30%;
	transition: all 0.3s;
}

.Content-Dropzone-Media p {
	grid-column-start: 2;
	grid-column-end: 3;
	grid-row-start: 2;
	grid-row-end: 2;
}
.Content-Dropzone-Simple {
	position: relative;
	border: dashed 3px #999;
	height: 250px;
	padding: 60px 20px 0;
	text-align: center;
	color: #000 !important;
	border-radius: 5px;
}

.Content-Dropzone-Simple h4 {
	color: #888 !important;
	font-size: 24px;
}

.Dropzone-Simple p {
	color: #444 !important;
	font-size: 14px;
	margin: 0;
}
.Dropzone-Simple {
	cursor: pointer;
	padding: 10px 20px;
	background-color: #fafafa;
	border-radius: 4px;
	border: solid 1px #ddd;
	width: 200px;
	transition: all 0.3s ease;
	margin: 0 auto;
}

.Dropzone-Simple:hover {
	background-color: #efefef;
}

.full-screen {
	width: 90vw !important;
	min-height: 90vh !important;
	margin: 10px auto;
	max-width: 100% !important;
}

.bg-transparent {
	background: url('https://opengameart.org/sites/default/files/Transparency500.png');
	background-repeat: repeat;
	padding: 0;
	background-size: 120px;
}
.modal-content {
	height: 100%;
}
.btn-success {
	background-color: #08a742 !important;
	border-color: #08a742 !important;
}
.bp3-dialog {
	background: #fff !important;
	border-radius: 4px !important;
}
.modal-xxl {
	min-width: 100% !important;
	width: 100% !important;
	margin: 0 !important;
}
.modal-xxl .modal-body {
	height: calc(100vh - 131px) !important;
	overflow-y: scroll !important;
}
.modal-height-scroll {
	margin: 0 auto !important;
}
.modal-height-scroll .modal-body {
	height: calc(100vh - 131px) !important;
	overflow-y: scroll !important;
}
.modal-fullwidth {
	width: 100% !important;
	height: 100% !important;
	max-width: 100% !important;
	max-height: 100% !important;
}
/* .bp3-dialog {
    border-radius: 0 !important;
    width: 700px !important;
} */

/* @media screen and (max-width: 468px) {
    .bp3-dialog {
        width: 320px !important;
    }
} */
