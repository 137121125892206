body {
	font-family: 'Inter', sans-serif;
	overflow-y: auto;
	color: #1C434C;
}
.ql-tooltip {
	left: 0 !important;
	z-index: 9999;
}

.ql-snow .ql-tooltip input[type='text'] {
	width: 120px;
	color: black;
}

.align-items-center {
	display: flex;
	align-items: center;
}
.justify-center {
	display: flex;
	justify-content: center;
}
#graph {
	display: flex;
	justify-content: center;
	align-items: center;
}

.sidebar {
	background-color: #031b4d;
	box-shadow: 4px 0 5px rgba(0, 0, 0, 0.05);
	min-height: 100vh;
	position: fixed;
	width: 220px;
	transition: all 0.3s ease;
	height: 100vh;
	padding-top: 10px;
	z-index: 1049;
	overflow-y: scroll;
}

.data-table {
	display: table-cell;
}

.top-element {
	z-index: 2000;
}

.sidebar::-webkit-scrollbar {
	display: none;
}

.collapse-sidebar {
	width: 60px;
}

.bp3-button span {
	pointer-events: none;
}

.bp3-toast-container {
	z-index: 1060 !important;
}

.bp3-progress-bar {
	border-radius: 0 !important;
}

.bp3-progress-bar .bp3-progress-meter {
	border-radius: 0 !important;
}

.outer-wrapper {
	width: 100%;
}

.bg-lightgrey {
	background-color: #f8fafc;
}

.comment-submit {
	border-radius: 4px;
	padding: 10px;
}

.comment-controls {
	z-index: 20;
}

@media (min-width: 800px) {
	.outer-wrapper {
		width: 100%;
		padding-left: 0px;
	}
}

@media (max-width: 500px) {

	#intercom-frame,
	#intercom-app {
		display: none !important;
	}
}

@media (min-width: 1024px) {
	.collapse-outer-wrapper {
		padding-left: 60px !important;
	}

	.outer-wrapper {
		padding-left: 220px;
		width: 100%;
		transition: all 0.3s ease !important;
	}
}

#master_container {
	position: relative;
}

@media (max-width: 1024px) {
	.sidebar {
		display: none;
	}
}

/* CONTENT */

/* .content { */
	/* margin-top: 32px; */
/* } */

/* SIDEBAR */

.sidebar-top-section {
	position: relative;
	padding: 19px 20px;
	transition: all 0.3s ease;
}

.sidebar-top-section-collapse {
	padding: 19px 9px !important;
}

.add-property-btn {
	position: absolute;
	float: right;
	right: 20px;
	transition: all 0.3s ease;
	opacity: 1;
}

.btn-white {
	background: #fff;
	border: 1px solid #eee;
	border-radius: 2px;
	font-size: 1rem;
	padding: 5px 10px;
	font-weight: 600;
	cursor: pointer;
}

.btn-back {
	background: transparent;
	border: 0;
	color: #127cbd;
	transition: all 0.3s;
}

.btn-back:hover {
	cursor: pointer;
	transition: all 0.3s;
	font-weight: 600;
}

.add-property-btn-collapse {
	opacity: 0 !important;
	z-index: -1;
}

.sidebar__logo {
	height: 36px;
	width: 35px;
	object-fit: contain;
	max-width: none;
	transition: all 0.3s ease;
}

.sidebar__logo__collapse {
	height: 43px !important;
	width: 42px !important;
	max-width: none;
}

.sidebar__icon-plus {
	opacity: 0.7;
}

.sidebar__icon-plus:hover {
	opacity: 1;
	cursor: pointer;
}

.sidebar-navigation-items {
	opacity: 1;
	transition: opacity 0.5s ease;
}

.sidebar-navigation-items-collapse {
	padding-top: 1.5rem;
	border-top: 1px solid rgba(180, 188, 199, 0.32);
}

.sidebar-navigation-item {
	color: #fff;
	display: block;
	position: relative;
	padding: 10px 20px;
	font-size: 16px;
	font-weight: 400;
	border-radius: 0 4px 4px 0;
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
	text-decoration: none;
	opacity: 0.9;
}

.custom-select {
	background: #fff url(../svg/caret-down.svg) no-repeat right 0.75rem center;
	background-size: 8px;
}

.sidebar-navigation-item:hover {
	background-color: #00000063;
	color: #fff;
	text-decoration: none;
	opacity: 1;
}

.sidebar-navigation-item.active {
	color: #fff;
	background: #00000063;
	opacity: 1;
	border-radius: 0px;
	border-left: 2px solid #E8C253;
}

.sidebar-navigation-item-icon {
	right: 20px;
	margin-right: 20px;
	margin-left: -40px;
}

.sidebar-navigation-section>h5 {
	text-transform: uppercase;
	color: #fff;
	opacity: .6;
	font-weight: 600;
	padding: 25px 0px 10px 20px;
	font-size: 0.8rem;
	line-height: 0.8rem;
	letter-spacing: 0.06rem;
	-webkit-transition: padding 0s linear 0.4s;
	-o-transition: padding 0s linear 0.4s;
	transition: padding 0s linear 0.4s;
	cursor: pointer;
}

.sidebar-navigation-section {
	padding-bottom: 1.5rem;
	border-bottom: 1px solid rgba(180, 188, 199, 0.32);
}

/* ***********************
*         BUTTONS        *
**************************/

.btn i,
i {
	pointer-events: none;
}

.btn {
	transition: all 0.3s;
	letter-spacing: 0px;
}

.btn-transparent {
	background: transparent;
	outline: 0;
}

.btn-actions {
	background-color: #4b789f;
	color: #fff;
}

.btn-bordered {
	border: 1px solid #1287c4;
	font-weight: 400;
	font-size: 15px;
	color: #5d5d5d;
	transition: all 0.3s;
}

.btn-bordered i {
	margin-right: 5px;
}

.btn-bordered:hover {
	transform: scale(1.05);
}

/* ***********************
*       CONTACTS         *
**************************/

.actions-item {
	display: inline-block;
	padding-top: 5px;
	padding-right: 2rem;
}

.actions-btn {
	display: inline-block;
	margin-top: -0.8rem;
}

.card__profile {
	padding: 2rem;
}

.card__profile--company {
	margin-top: -0.5rem;
}

.card__profile--title {
	display: flex;
	align-items: center;
	font-weight: 600;
}

.card__profile--item {
	opacity: 0.5;
	padding-top: 1rem;
	margin-bottom: 0;
}

/* ***********************
*   PROPERTIES CARDS     *
**************************/

.card__property-description {
	padding: 0.75rem;
}

.card__property-title {
	font-weight: 700;
	color: #06b1d7;
}

.card__property-title span {
	display: inline-block;
	max-width: 270px;
	white-space: nowrap;
	overflow: hidden !important;
	text-overflow: ellipsis;
}

.card__property-price {
	font-size: 1.2rem;
	font-weight: 700;
	display: inline-block;
}

.card__property-listingType {
	text-transform: uppercase;
	letter-spacing: 2px;
	display: inline-block;
	padding-left: 1rem;
	font-size: 0.7rem;
	opacity: 0.5;
}

.card__property-location {
	font-size: 0.9rem;
	opacity: 0.6;
}

.card__property-ammenities {
	display: inline-block;
	font-size: 0.9rem;
	opacity: 0.6;
	cursor: default !important;
}

.card__property-ammenities-item {
	margin-left: 15px;
	display: inline-block;
}

.card__property-ammenities-item:first-child {
	margin-left: 0px;
}

/* PROPERTIES DETAILS */

.property-specs span {
	margin-left: 0.4rem;
	cursor: default;
}

.card__property-sliderContainer {
	padding: 0px;
	cursor: -webkit-grab;
}

.card__property-sliderContainer:active {
	padding: 0px;
	cursor: -webkit-grabbing;
}

.card__property-slider--Image {
	width: 100%;
	background-size: cover;
	max-height: 500px;
}

.card__property-details-aeId {
	font-size: 0.8rem;
	opacity: 0.7;
}

.card__property-details-name {
	font-size: 1.1rem;
	letter-spacing: -0.5px;
}

.card__property-details-price {
	font-size: 1.5rem;
	display: inline-block;
	font-weight: 700;
	display: block;
	cursor: default;
}

.card__property-details-price span.small {
	font-size: 12px;
	text-transform: uppercase;
	margin-left: 5px;
	letter-spacing: 2px;
	margin-top: 2px;
}

.card__property-detailsCard {
	background-color: #f5f7fb;
	color: #7a7a7a;
	padding: 1rem;
	border-radius: 4px;
}

.card__property-details-listingType {
	display: inline-block;
	text-transform: uppercase;
	letter-spacing: 2px;
	margin-left: 10px;
}

.card__property-details-section {
	font-size: 1.2rem;
}

.share-btn {
	margin-top: 1rem;
	width: 100%;
}

.share-btn:first-child {
	margin-top: 0rem;
}

.features-list {
	padding-left: 0;
	list-style: none;
	columns: 2;
	-webkit-columns: 2;
	-moz-columns: 2;
}

.colorBlue {
	color: #007cab;
}

.features-list li i {
	font-size: 0.8rem;
}

.property-feature-desc {
	font-weight: 700;
	text-transform: uppercase;
}

/* End Properties Details */

/* PROPERTIES SEARCH */
@media (min-width: 800px) {
	.enable-scroll {
		height: calc(100vh - 55px);
		overflow-y: scroll;
	}
}

.row-search {
	background-color: #fff;
	padding: 10px 10px 10px 22px;
	width: 100%;
	margin-top: -2rem;
	margin-bottom: 2rem;
}

.search__filter {
	display: inline-block;
	margin-left: 1rem;
}

.search__filter:first-child {
	margin-left: 0rem;
}

.content-fields {
	padding: 10px 20px;
}

.card-container {
	padding: 2rem 1rem 2rem 1rem;
}

/* ***********************
*     UTILITIES          *
**************************/
.flex-right-center {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.flex-left-center {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.flex-center-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.orange {
	color: orange;
}

.align-items-center {
	display: flex;
	align-items: center;
}

.remove-link {
	color: #495057 !important;
}

.remove-link:hover {
	text-decoration: underline;
}

.no-style-ul {
	padding: 0;
	margin: 0;
	list-style-type: none;
}

.remove-link.pointer {
	cursor: pointer;
}

.fake-link {
	color: #467fcf !important;
}

.fake-link-no-color:hover {
	color: #467fcf !important;
}

.fake-link:hover {
	text-decoration: underline;
}

.fake-link.pointer {
	cursor: pointer;
}

@media (min-width: 576px) {
	.container {
		max-width: 900px;
	}
}

@media (min-width: 768px) {
	.container {
		max-width: 992px;
	}
}

@media (min-width: 992px) {
	.container {
		max-width: 1200px;
	}
}

@media (min-width: 1280px) {
	.container {
		max-width: 1440px;
	}
}

.p0 {
	padding: 0 !important;
}

.mmt2 {
	/* Minus Margin Top -2 */
	margin-top: -2rem !important;
}

.mh100 {
	/* min-height 100% vh; */
	min-height: 100vh;
}

.not-available {
	opacity: 0.7;
}

.low-opacity {
	opacity: 0.5;
}

.listnone {
	padding: 0 !important;
	list-style: none !important;
}

.no-gutter {
	padding: 0 !important;
}

.no-gutters {
	margin-right: 0;
	margin-left: 0;
}

.no-gutters>.col,
.no-gutters>[class*='col-'] {
	padding-right: 0;
	padding-left: 0;
}

.text-align-right {
	text-align: right !important;
}

.bringTop {
	z-index: 90000 !important;
}

.ml-0 {
	margin-left: 0 !important;
}

.mr-0 {
	margin-right: 0 !important;
}

.m-0 {
	margin: 0 !important;
}

.p-0 {
	padding: 0 !important;
}

.min50 {
	min-height: 65vh;
}

.link {
	color: #317ae2;
	cursor: pointer;
}

.link:hover {
	text-decoration: underline;
}

.flex-end {
	display: flex;
	justify-content: flex-end;
}

@media (min-width: 1000px) {
	.text-right-lg {
		text-align: right !important;
	}

	.control-label {
		padding-top: 7px;
	}
}

.fs-1 {
	font-size: 1rem;
}

.danger {
	color: rgb(255, 0, 0);
}

.warning {
	color: orange;
}

.danger-circle {
	background: #ff00009e;
	border-radius: 50%;
	height: 8px;
	width: 8px;
	margin-left: 7px;
}

.filter-circle {
	background: transparent;
	border-radius: 50%;
	height: 8px;
	width: 8px;
	margin-left: 7px;
}

.inline-block {
	display: inline-block !important;
}

.block {
	display: block !important;
}

.pgrey {
	color: #7a7a7a;
}

.color-primary {
	color: rgb(3, 149, 182);
}

/* Tabler React */

.nav-tabs {
	margin: 0;
}

.ql-editor {
	min-height: 100px;
}

.quill {
	background-color: #fff;
}

.card-design-properties {
	width: 100%;
	height: 100%;
	position: relative;
	float: left;
	background-color: #fff;
}

.card-stat-icon {
	position: relative;
}

.card-stat-icon svg {
	position: absolute;
	opacity: 0.3;
	left: 10px;
	top: 25px;
	width: 35px;
	height: 35px;
}

.icon-amenitie-sector {
	cursor: pointer;
}

.requerido {
	padding: 0;
}

.selectgroup-item {
	margin-bottom: 0 !important;
}

.active-search {
	background: #fff;
}

.active-search:active {
	background: #316cbe;
}

.rdw-editor-main {
	min-height: 300px;
	border: 1px solid #f3f3f3;
}

.property-image-card {
	position: relative;
	float: left;
	width: 100% !important;
	height: 250px !important;
}

.loading-container {
	position: fixed;
	float: left;
	left: 0%;
	top: 0%;
	left: 220px;
	z-index: 99999;
	background: rgba(255, 255, 255, 0.9);
	height: 100%;
	width: calc(100% - 220px);
	text-align: center;
	border-radius: 5px;
}

.content {
	position: relative;
}

.loading {
	position: relative;
	float: left;
	width: 100%;
	top: 25%;
	text-align: center;
}

.loading-image {
	position: relative;
	float: left;
	width: 115px;
	left: calc(50% - 55px);
	margin-top: 25px;
}

@media screen and (max-width: 1023px) {
	.loading-container {
		left: 0px !important;
		width: 100% !important;
	}
}

.agent-fullName {
	font-weight: 700;
	font-size: 1.2rem;
}

#agent-card {
	transition: all 0.3s;
}

#agent-card:hover {
	transform: translateY(-1px);
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.062);
}

.form-group-sm {
	padding-bottom: 5px;
}

.form-footer {
	border-top: 1px solid #eee;
	padding-top: 1rem;
}

.social-links-contact>li {
	list-style: none;
	display: inline-block;
	margin-right: 0.5rem;
}

.social-links-contact>li>a {
	text-decoration: none;
	color: #000;
	text-align: center;
	display: inline-block;
	height: 1.75rem;
	width: 1.75rem;
	line-height: 1.75rem;
	text-align: center;
}

.social-links-contact {
	padding-left: 0rem;
	display: inline-block;
	margin-top: 0.5rem;
}

.social-links-contact>ul {
	padding-left: 0rem;
}

.remove-picture {
	position: absolute;
	z-index: 999;
	border-radius: 50px;
	background: #fff;
	color: #000;
	cursor: pointer;
	border: solid 1px #eee;
	box-shadow: 0 0 3px #000;
	top: 5px;
	left: 5px;
	display: none;
	outline: none !important;
}

.remove-picture:hover {
	background: red;
	color: #fff;
	border-color: red;
}

.imagecheck:hover button {
	display: block;
}

.imagecheck i {
	pointer-events: none;
}

.amenities-checklist {
	list-style: none;
	padding-left: 0;
	columns: 3;
	-webkit-columns: 3;
	-moz-columns: 3;
}

.hot {
	color: #fff;
	background-color: red;
	font-size: 9px;
	font-weight: 800;
	padding: 4px 6px;
	margin-left: 5px;
	border-radius: 2px;
}

.ReactModal__Overlay {
	z-index: 1200 !important;
}

.modal-nm {
	margin-top: 0 !important;
}

/* Modal Fixed Fullwidth */

/* MODAL FIXED */

.modal-full {
	min-width: 100%;
	margin: 0;
}

.modal-full .modal-content {
	min-height: 100vh;
}

.modal-full .modal-header {
	position: fixed;
	top: 0;
	z-index: 9000000 !important;
	width: 100%;
	background: #ffff;
	left: 12px;
}

.modal-full .modal-footer {
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: 9000000 !important;
	background: #ffff;
	padding: 10px;
	border-radius: 0px;
}

.modal-full .modal-body {
	height: 300px;
	overflow: scroll;
	padding-top: 70px;
	padding-bottom: 70px;
}

/* PAGINATION */

.page-link.selected {
	background-color: #008abd;
	color: #fff;
	font-weight: 600;
	outline: none;
}

@media (max-width: 600px) {
	.pagination {
		margin-bottom: 0px !important;
	}
}

.btn-pagination {
	background: rgb(243, 243, 243);
	color: #031b4d;
	margin-left: 1rem;
	border: 1px solid rgb(209, 209, 209);
}

.btn-outline-primary {
	display: flex;
	align-items: center;
	color: #656565;
}

.btn-outline-primary.active {
	background: #467fcf;
	color: #fff;
}

.btn-outline-primary svg {
	margin-right: 5px;
	color: #467fcf;
}

.btn-outline-primary:hover svg,
.btn-outline-primary.active svg {
	margin-right: 5px;
	color: white;
	pointer-events: none;
}

.btn-dropdown {
	padding: 5px 8px 0px 8px;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	border-radius: 4px;
	cursor: pointer;
}

.btn-dropdown:hover {
	background-color: #f7f7f7;
}

.btn-dropdown:active {
	background-color: #eee;
}

.btn-pagination:hover {
	background-color: #031b4d;
	color: #fff;
	border: 1px solid #031b4d;
	box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.075);
}

.disabled {
	cursor: not-allowed !important;
}

.table-not-found {
	display: block;
	width: 100%;
	margin-top: 1rem;
	position: absolute;
	text-align: center;
}

.property-badge {
	display: inline-block;
}

@media screen and (max-width: 568px) {
	.property-badge {
		top: -22px !important;
	}
}

.p-0 {
	padding: 0 !important;
}

.m-0 {
	margin: 0 !important;
}

.property-badge.true {
	background-color: yellowgreen;
	color: #fff;
	margin-left: 1rem;
	font-size: 0.8rem;
	font-weight: 900;
	position: absolute;
	top: 0px;
	border-top-right-radius: 3px;
	right: 0px;
	padding: 2px 9px;
}

.property-badge.false {
	background-color: red;
	color: #fff;
	margin-left: 1rem;
	font-size: 0.8rem;
	font-weight: 900;
	position: absolute;
	top: 0px;
	border-top-right-radius: 3px;
	right: 0px;
	padding: 2px 9px;
}

.slick-prev {
	z-index: 9 !important;
	left: 8px;
	transform: scale(1.2);
	opacity: 0.5;
	transition: all 0.3s;
}

.slick-dots {
	bottom: 20px !important;
}

.slick-dots li button:before {
	font-size: 9px;
	color: #fff !important;
}

.slick-next {
	opacity: 0.5;
	right: 8px;
	z-index: 10;
	transform: scale(1.2);
	transition: all 0.3s;
}

.slick-prev:hover,
.slick-next:hover {
	opacity: 1;
}

select option:disabled {
	color: red;
}

form.property-form span.bp3-popover-target {
	width: 100%;
}

.overview__container:first-child {
	padding-top: 0;
}

.overview__container {
	padding-top: 0.6rem;
}

.overview__title {
	opacity: 0.7;
}

.overview__data {
	color: #031b4d;
}

.overview__data ul {
	padding-left: 0px;
	list-style: none;
	margin-bottom: 0;
}

.grey {
	color: #7d7d7d;
}

.success {
	color: #5eba00;
}

.green {
	color: green;
}

.rc-select-selection--multiple {
	min-height: 2.38rem;
	border-radius: 2px;
	border-color: #e8eaf0;
}

.rc-select-selection__placeholder {
	top: 2px;
}

.rc-select-selection--multiple .rc-select-selection__rendered .rc-select-selection__choice {
	margin-top: 7px;
	line-height: 23px;
}

.rc-select-selection {
	border: 1px solid #e8eaf0 !important;
	border-radius: 3px !important;
}

.header {
	padding: 0;
	padding-top: 10px;
	padding-right: 10px;
	/* padding-left: 10px;
    padding-right: 15px; */
	border-bottom: 1px solid #f1f1f1;
	box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.03);
}

@media only screen and (max-width: 1024px) {
	.header-toggler {
		display: flex;
	}
}

@media only screen and (min-width: 1025px) {
	.header-toggler {
		display: none;
	}
}

@media only screen and (max-width: 600px) {
	.header {
		position: fixed;
		width: 100%;
		z-index: 999;
		top: 0;
	}

	.header-margin {
		padding-bottom: 3.5rem;
	}
}

/*==================================
=      SIDEBAR MOBILE
====================================*/

.backdrop {
	position: fixed;
	left: 0;
	background-color: rgba(0, 0, 0, 0.4);
	top: 0;
	width: 100vw;
	z-index: 1000 !important;
	height: 100vh;
}

.sidebar-mobile {
	position: absolute;
	z-index: 2000 !important;
	color: #fff;
	left: 0;
	top: 0;
	width: 60vw;
	background-color: #031b4d;
	box-shadow: 4px 0 5px rgba(0, 0, 0, 0.05);
	min-height: 100vh;
	position: fixed;
	min-width: 220px;
    overflow-y: auto;
    height: 100%;
}

button.goback {
	position: fixed;
	bottom: 10%;
	background-color: #fff;
	z-index: 10000000000;
	width: 40vw;
	padding: 10px;
	color: #000;
	border-top: 2px solid #007cab;
	border-right: 2px solid #007cab;
	border-bottom: 2px solid #007cab;
	font-size: 1rem;
	font-weight: 600;
	left: 0;
	/* box-shadow: 0px 0px 10px #00000045; */
	outline: none;
}

/*==================================
=      TYPOGRAPHY
====================================*/

.uppercase {
	text-transform: uppercase;
}

.capitalize {
	text-transform: capitalize;
}

.lightblue {
	color: #06b1d7 !important;
}

.strong {
	font-weight: 600;
}

.help-paragraph {
	opacity: 0.5;
}

/*==================================
=      MOBILE FILTER
====================================*/

.filter-mobile {
	position: fixed;
	z-index: 4000 !important;
	color: #000;
	left: 0;
	top: 0;
	width: 100vw;
	min-width: 100vw;
	background-color: #fff;
	min-height: 100vh;
	min-width: 220px;
}

.filter-mobile .title {
	display: flex;
	justify-content: space-between;
	padding: 1rem;
	padding-left: 1.3rem;
	padding-right: 1.3rem;
	border-bottom: 1px solid #e7e9e9;
	color: #2f3641;
}

.title .big {
	font-weight: 700;
	font-size: 1.2rem;
}

.filter-mobile-container {
	padding: 1.3rem;
	overflow: scroll;
	max-height: 680px;
	padding-bottom: 6rem;
}

.filter-mobile .apply {
	position: fixed;
	bottom: 0;
	width: 100%;
	background-color: #fff;
	color: #fff;
	border-top: 1px solid #e3e5e6;
	padding: 1rem;
	z-index: 90;
}

.filter-mobile .title .cancel {
	opacity: 0.4;
	font-size: 1.2rem;
}

.filter-mobile .title .delete {
	color: #031b4d;
	padding-top: 3px;
}

button.apply-filter {
	width: 100%;
	border: 0;
	padding: 10px;
	border-radius: 6px;
	color: #fff;
	font-weight: 600;
	font-size: 1rem;
	background-image: linear-gradient(to right, #031b4d, rgb(2, 40, 121));
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
	outline: none;
	transform: all 0.3s;
}

.filter-btn {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.filter-btn button {
	background-color: transparent;
	color: #fff;
	border: 0;
	outline: none;
}

@media (max-width: 600px) {

	/* .row-search {
        background-color: #001950;
        position: fixed;
        bottom: 0;
        margin-bottom: 0;
        z-index: 90000;
    } */
	.pagination {
		margin-bottom: 5rem !important;
	}

	.logo-mobile {
		position: absolute;
		align-items: center;
		justify-content: center;
		display: flex;
		left: 31%;
		bottom: 12px;
		height: 40px;
	}

	.logo-mobile img {
		height: 30px;
	}
}

.status-filter {
	display: inline-block !important;
}

.status-filter button {
	background-color: #fff;
	border: 1px solid #4c525a;
	margin-right: 5px;
	outline: none;
	transition: all 0.3s;
	cursor: pointer;
}

.status-filter button.active {
	background-color: #001950;
	color: #fff;
	border: 1px solid #001950;
}

.bp3-toast-container {
	z-index: 500;
}

/*==================================
=      MULTI-SELECT (REACT-SELECT)
====================================*/

.react-select__control {
	border-radius: 2px !important;
	border-color: #e0e5ed !important;
}

@media only screen and (max-width: 600px) {
	.react-select__control {
		font-size: 16px;
	}
}

.react-select__control--is-focused {
	border-color: #1991eb !important;
	outline: 0 !important;
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(25, 145, 235, 0.5) !important;
}

/*==================================
=      RECOMMENDATIONS
====================================*/

.recommendations {
	display: flex;
	flex: 1;
	flex-direction: column;
	margin-right: 1rem;
	margin-left: 1rem;
	padding-top: 10px;
}

.recommendations .budget {
	margin-bottom: 1rem;
}

.recommendations .price {
	font-weight: 600;
}

/*==================================
=      SORT TABLE
====================================*/

.sortable svg {
	pointer-events: none;
}

.d-inline {
	display: inline-block !important;
}

.sortTable {
	border: 1px solid #eee;
}

.sortTable .active {
	background-color: #e5e5e5;
}

.sortTable th span {
	display: inline-block;
}

.sortTable .sortable {
	cursor: pointer;
}

.d-inline {
	display: inline-block;
}

.profile .bp3-tab-list {
	padding-left: 15px;
	padding-top: 7px;
}

.bp3-tab[aria-selected='true'],
.bp3-tab:not([aria-disabled='true']):hover {
	outline: 0;
}

.bp3-tab-panel {
	margin-top: 8px;
	border-top: 1px solid rgba(0, 40, 100, 0.12);
}

.profile .documents {
	margin: 10px;
}

.documents {
	border: 1px solid transparent;
}

.document--item {
	padding: 5px 0px 5px 10px;
	border-bottom: 1px solid #eee;
	transition: all 0.3s;
}

.document--item.won {
	border-left: 2px solid #4caf50;
}

.won {
	color: #4caf50 !important;
}

.lost {
	color: #f94839 !important;
}

.white {
	background: #fff !important;
}

.document--item.open {
	border-left: 2px solid #1991eb;
}

.document--item.lost {
	border-left: 2px solid red;
}

.document--item:hover {
	box-shadow: 0px 0px 5px #00000012;
}

.document--item a,
.document--item button {
	color: rgb(44, 44, 44);
	opacity: 0.5;
	transition: all 0.3s;
}

.document--item a:hover,
.document--item button:hover {
	color: rgb(44, 44, 44);
	opacity: 1;
}

.additional-fields {
	padding-top: 10px;
	padding-bottom: 10px;
	border-top: 1px solid #eee;
	border-bottom: 1px solid #eee;
	cursor: pointer;
	margin-top: 5px;
	margin-bottom: 5px;
	transition: all 0.3s;
}

.additional-fields:active {
	background-color: #eee;
}

/* Activity */

.bordered {
	padding: 10px 25px;
	border: 1px solid #f3f3f3;
}

.bordered a {
	margin-left: 15px;
	color: #007cab !important;
}

.flex-row {
	flex-direction: row !important;
}

/*==================================
=      Fade In Effect css
====================================*/

@keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.fadein {
	animation: fadein 0.5s !important;
}

/*==================================
=      Activity Form
====================================*/

/* .content-daypicker .DayPicker {
    transform: scale(.9) !important;
} */

.btn-action {
	box-shadow: none !important;
	outline: none !important;
	padding: 7px 5px 7px 5px !important;
	margin-bottom: 1rem;
	border-radius: 0px !important;
	border-right: none !important;
}

.btn-action i {
	opacity: 0.9;
}

.btn-action:first-child {
	border-top-left-radius: 2px !important;
	border-bottom-left-radius: 2px !important;
}

.btn-action:last-child {
	border-top-right-radius: 2px !important;
	border-bottom-right-radius: 2px !important;
	border-right: 1px solid rgba(0, 40, 100, 0.12) !important;
}

.btn-action svg {
	pointer-events: none !important;
}

/*==================================
=      DAYPICKER
====================================*/

.bp3-datepicker {
	position: relative;
	border-radius: 3px;
	background: #fff;
	padding: 5px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.bp3-datepicker .DayPicker {
	display: inline-block;
	position: relative;
	min-width: 210px;
	vertical-align: top;
}

.bp3-datepicker .DayPicker:focus {
	outline: none;
}

.bp3-datepicker .DayPicker-Month {
	display: inline-table;
	margin: 0 5px 5px;
	border-collapse: collapse;
	border-spacing: 0;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.bp3-datepicker .DayPicker-Month+.bp3-datepicker .DayPicker-Month {
	margin-left: 10px;
}

.bp3-datepicker .DayPicker-Caption {
	display: table-caption;
}

.bp3-datepicker .DayPicker-Weekdays {
	display: table-header-group;
}

.bp3-datepicker .DayPicker-WeekdaysRow {
	display: table-row;
}

.bp3-datepicker .DayPicker-Weekday {
	display: table-cell;
	width: 30px;
	height: 30px;
	vertical-align: middle;
	text-align: center;
	line-height: 1;
	padding-top: 5px;
	font-weight: 600;
}

.bp3-datepicker .DayPicker-Weekday abbr[title] {
	text-decoration: none;
}

.bp3-datepicker .DayPicker-Body {
	display: table-row-group;
}

.bp3-datepicker .DayPicker-Week {
	display: table-row;
}

.bp3-datepicker .DayPicker-WeekNumber {
	color: rgba(92, 112, 128, 0.6);
	font-size: 14px;
}

.bp3-datepicker .DayPicker-Day,
.bp3-datepicker .DayPicker-WeekNumber {
	display: table-cell;
	width: 30px;
	height: 30px;
	vertical-align: middle;
	text-align: center;
	line-height: 1;
}

.bp3-datepicker .DayPicker-Day {
	border-radius: 3px;
	cursor: pointer;
}

.bp3-datepicker .DayPicker-Day.DayPicker-Day--outside {
	color: rgba(92, 112, 128, 0.6);
}

.bp3-datepicker .DayPicker-Day:focus,
.bp3-datepicker .DayPicker-Day:hover {
	background: #d8e1e8;
	color: #182026;
}

.bp3-datepicker .DayPicker-Day:active {
	background: #ced9e0;
}

.bp3-datepicker .DayPicker-Day.DayPicker-Day--selected {
	border-radius: 3px;
	background-color: #137cbd;
	color: #fff;
}

.bp3-datepicker .DayPicker-Day.DayPicker-Day--selected:hover {
	background-color: #106ba3;
}

.bp3-datepicker .DayPicker-Day.DayPicker-Day--disabled {
	background: none;
	cursor: not-allowed;
	color: rgba(92, 112, 128, 0.6);
}

.bp3-datepicker-navbar {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	height: 30px;
}

.bp3-datepicker-navbar>.DayPicker-NavButton--prev {
	margin-right: auto;
}

.bp3-datepicker-navbar>.DayPicker-NavButton--next {
	margin-left: auto;
}

.bp3-datepicker-caption {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin: 0 25px 5px;
}

.bp3-datepicker-caption>* {
	-webkit-box-flex: 0;
	-webkit-flex-grow: 0;
	-ms-flex-positive: 0;
	flex-grow: 0;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
}

.bp3-datepicker-caption> :first-child {
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-flex-shrink: 1;
	-ms-flex-negative: 1;
	flex-shrink: 1;
}

.bp3-datepicker-caption select {
	padding-right: 16px;
	padding-left: 5px;
	font-weight: 600;
}

.bp3-datepicker-caption select+.bp3-icon {
	right: 2px;
}

.bp3-datepicker-caption+.bp3-divider {
	margin: 0;
}

.bp3-datepicker-month-select,
.bp3-datepicker-year-select {
	-webkit-flex-shrink: 1;
	-ms-flex-negative: 1;
	flex-shrink: 1;
}

.bp3-datepicker-year-select {
	min-width: 60px;
}

.bp3-datepicker-caption-measure {
	padding-left: 5px;
	font-weight: 600;
}

.bp3-datepicker-footer {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.bp3-dark .bp3-datepicker {
	background: #30404d;
}

.bp3-dark .bp3-datepicker .DayPicker-Day.DayPicker-Day--outside,
.bp3-dark .bp3-datepicker .DayPicker-WeekNumber {
	color: rgba(167, 182, 194, 0.6);
}

.bp3-dark .bp3-datepicker .DayPicker-Day:focus,
.bp3-dark .bp3-datepicker .DayPicker-Day:hover {
	background: #5c7080;
	color: #fff;
}

.bp3-dark .bp3-datepicker .DayPicker-Day:active {
	background: #738694;
}

.bp3-dark .bp3-datepicker .DayPicker-Day.DayPicker-Day--selected {
	background-color: #137cbd;
}

.bp3-dark .bp3-datepicker .DayPicker-Day.DayPicker-Day--selected:hover {
	background-color: #2b95d6;
}

.bp3-dark .bp3-datepicker .DayPicker-Day.DayPicker-Day--disabled {
	background: none;
	color: rgba(167, 182, 194, 0.6);
}

.bp3-dark .bp3-datepicker .bp3-datepicker-footer {
	border-top-color: rgba(16, 22, 26, 0.4);
}

.bp3-daterangepicker {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	white-space: nowrap;
}

.bp3-daterangepicker .DayPicker:first-of-type .bp3-datepicker-navbar {
	left: 5px;
}

.bp3-daterangepicker .DayPicker-NavButton--interactionDisabled {
	display: none;
}

.bp3-daterangepicker .bp3-daterangepicker-timepickers {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
}

.bp3-daterangepicker.bp3-daterangepicker-contiguous .DayPicker {
	min-width: 220px;
}

.bp3-daterangepicker.bp3-daterangepicker-single-month .DayPicker {
	min-width: 210px;
}

.bp3-daterangepicker .DayPicker-Day--outside {
	visibility: hidden;
}

.bp3-daterangepicker .DayPicker-Day--hovered-range {
	border-radius: 0;
}

.bp3-daterangepicker .DayPicker-Day--hovered-range:not(.DayPicker-Day--selected):not(.DayPicker-Day--selected-range):not(.DayPicker-Day--selected-range-start):not(.DayPicker-Day--selected-range-end) {
	background-color: #ebf1f5;
}

.bp3-daterangepicker .DayPicker-Day--selected-range {
	border-radius: 0;
	background-color: #ebf1f5;
}

.bp3-daterangepicker .DayPicker-Day--selected-range:hover {
	background-color: #ced9e0;
}

.bp3-daterangepicker .DayPicker-Day--selected-range-start:not(.DayPicker-Day--selected-range-end):not(.DayPicker-Day--hovered-range-end) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.bp3-daterangepicker .DayPicker-Day--selected-range-end:not(.DayPicker-Day--selected-range-start):not(.DayPicker-Day--hovered-range-start) {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.bp3-daterangepicker .DayPicker-Day--hovered-range-start:not(.DayPicker-Day--hovered-range-end) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.bp3-daterangepicker .DayPicker-Day--hovered-range-end:not(.DayPicker-Day--hovered-range-start) {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.bp3-dark .bp3-daterangepicker .DayPicker-Day--hovered-range:not(.DayPicker-Day--selected):not(.DayPicker-Day--selected-range):not(.DayPicker-Day--selected-range-start):not(.DayPicker-Day--selected-range-end) {
	background-color: #394b59;
}

.bp3-dark .bp3-daterangepicker .DayPicker-Day--selected-range {
	background-color: #394b59;
}

.bp3-dark .bp3-daterangepicker .DayPicker-Day--selected-range:hover {
	background-color: #5c7080;
}

.bp3-menu.bp3-daterangepicker-shortcuts {
	min-width: 120px;
	padding: 0;
}

.bp3-timepicker {
	white-space: nowrap;
}

.bp3-timepicker .bp3-timepicker-arrow-row {
	padding: 0 1px;
}

.bp3-timepicker .bp3-timepicker-arrow-button {
	color: #5c7080;
	display: inline-block;
	width: 33px;
	padding: 4px 0;
	text-align: center;
}

.bp3-timepicker .bp3-timepicker-arrow-button:hover {
	color: #182026;
}

.bp3-dark .bp3-timepicker .bp3-timepicker-arrow-button {
	color: #a7b6c2;
}

.bp3-dark .bp3-timepicker .bp3-timepicker-arrow-button:hover {
	color: #f5f8fa;
}

.bp3-timepicker .bp3-timepicker-arrow-button+.bp3-timepicker-arrow-button {
	margin-left: 11px;
}

.bp3-timepicker .bp3-timepicker-arrow-button:hover {
	cursor: pointer;
}

.bp3-timepicker .bp3-timepicker-input-row {
	display: inline-block;
	border-radius: 3px;
	-webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
	box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
	background: #fff;
	height: 30px;
	padding: 0 1px;
	vertical-align: middle;
	line-height: 28px;
}

.bp3-timepicker .bp3-timepicker-divider-text {
	display: inline-block;
	width: 11px;
	text-align: center;
	color: #5c7080;
	font-size: 16px;
}

.bp3-timepicker .bp3-timepicker-input {
	outline: 0;
	border: 0;
	border-radius: 3px;
	-webkit-box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0);
	box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0);
	background: transparent;
	width: 33px;
	height: 28px;
	padding: 0;
	text-align: center;
	color: #182026;
	-webkit-transition: -webkit-box-shadow 0.1s cubic-bezier(0.4, 1, 0.75, 0.9);
	transition: -webkit-box-shadow 0.1s cubic-bezier(0.4, 1, 0.75, 0.9);
	transition: box-shadow 0.1s cubic-bezier(0.4, 1, 0.75, 0.9);
	transition: box-shadow 0.1s cubic-bezier(0.4, 1, 0.75, 0.9), -webkit-box-shadow 0.1s cubic-bezier(0.4, 1, 0.75, 0.9);
}

.bp3-timepicker .bp3-timepicker-input:focus {
	-webkit-box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3);
	box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3);
}

.bp3-timepicker .bp3-timepicker-ampm-select {
	margin-left: 5px;
}

.bp3-timepicker.bp3-disabled .bp3-timepicker-input-row {
	-webkit-box-shadow: none;
	box-shadow: none;
	background: rgba(206, 217, 224, 0.5);
	cursor: not-allowed;
	color: rgba(92, 112, 128, 0.6);
	resize: none;
}

.bp3-timepicker.bp3-disabled .bp3-timepicker-divider-text,
.bp3-timepicker.bp3-disabled .bp3-timepicker-input {
	cursor: not-allowed;
	color: rgba(92, 112, 128, 0.6);
}

.bp3-timepicker.bp3-disabled .bp3-timepicker-arrow-button,
.bp3-timepicker.bp3-disabled .bp3-timepicker-arrow-button:hover {
	cursor: not-allowed;
	color: rgba(92, 112, 128, 0.6);
}

.bp3-dark .bp3-timepicker .bp3-timepicker-input-row {
	-webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
	box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
	background: rgba(16, 22, 26, 0.3);
}

.bp3-dark .bp3-timepicker .bp3-timepicker-divider-text {
	color: #a7b6c2;
}

.bp3-dark .bp3-timepicker .bp3-timepicker-input {
	color: #f5f8fa;
}

.bp3-datepicker .bp3-timepicker {
	margin-top: 5px;
	margin-bottom: 10px;
	text-align: center;
}

.bp3-datepicker .bp3-timepicker:last-child {
	margin-bottom: 5px;
}

.bp3-datetimepicker {
	border-radius: 3px;
	background-color: #fff;
	padding: 10px;
	text-align: center;
}

.bp3-dark .bp3-datetimepicker {
	background: #30404d;
}

.bp3-dark .bp3-datetimepicker .bp3-datepicker {
	border-bottom: 1px solid rgba(16, 22, 26, 0.4);
}

.bp3-datetimepicker .bp3-datepicker {
	border-bottom: 1px solid rgba(16, 22, 26, 0.15);
	padding: 0 0 10px;
}

.bp3-datetimepicker .bp3-timepicker {
	margin-top: 10px;
}

.bp3-dateinput-popover {
	padding: 0;
}

/*==================================
=      DRAGGABLE
====================================*/

.funnel__element {
	background: red;
	color: #fff;
}

/*==================================
=      ACTION ICON CARD COMMENT
====================================*/

.center-trash {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 2rem;
}

.flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.flex-center-full {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}

.flex-center-items {
	display: flex;
	align-items: center;
}

.icon-action {
	border-radius: 50%;
	border: solid 1px #f5f5f5;
	/* background: rgb(101, 170, 226); */
}

.svg-icon-action {
	color: rgb(101, 170, 226);
	fill: #fff;
}

.agent-icon-activity {
	position: absolute;
	float: right;
	right: 20px;
	bottom: 20px;
}

.close:before {
	content: '' !important;
}

@media only screen and (max-width: 600px) {
	.form-control {
		font-size: 16px !important;
	}
}

.form-control select {
	-webkit-appearance: none;
}

.input-phone {
	border: solid 1px #eee;
	height: 36px;
	border-radius: 0;
	padding: 5px;
	outline: none;
	position: relative;
	float: left;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {

	.deal *,
	.title-stage * {
		font-size: 13px;
	}
}

@media screen and (max-width: 768px) {

	.deal *,
	.title-stage * {
		font-size: 11px;
	}
}

.activity-indicator i {
	font-size: 12px;
}

.activity-indicator.warning {
	color: #ffb90e;
}

.activity-indicator.danger {
	color: #ff6e6e;
}

.activity-indicator.ok {
	color: #ababab;
}

.title-stage {
	display: inline-block;
}

.content-title-stage {
	display: flex;
	flex-flow: row nowrap;
	position: relative;
}

.content-title-stage #arrow {
	position: absolute;
	top: 0;
	margin-left: -18px;
}

.target-controller {
	min-width: 150px;
	height: 45px;
	display: inline-block;
	width: 25%;
	border-radius: 4px;
	box-sizing: border-box;
	border: 2px solid;
	font-size: 1.2rem;
	font-weight: 600;
	text-align: center;
	padding-top: 5px;
	margin: 1rem;
}

.target-controller.lost {
	color: #fff;
	background-color: red;
	border-color: #da0000;
}

.target-controller.won {
	color: #fff;
	background-color: green;
	border-color: #007100;
}

.target-controller.trash {
	color: grey;
	background-color: #eee;
	border-color: #e2e2e2;
}

.target-controller.change {
	color: grey !important;
	background-color: #fff;
	border-color: #e2e2e2;
	border-style: dashed;
}

.sidebar-toggle {
	display: none;
}

@media only screen and (max-width: 991px) {
	.sidebar-toggler {
		display: block;
	}
}

.recipe-toaster {
	z-index: 99999;
}

.status-filter {
	display: inline-block !important;
}

.status-filter button {
	background-color: #fff;
	border: 1px solid #4c525a;
	margin-right: 5px;
	outline: none;
	transition: all 0.3s;
	cursor: pointer;
}

.status-filter button.active {
	background-color: #001950;
	color: #fff;
	border: 1px solid #001950;
}

.actions {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.actions-container {
	/* margin-top: -2rem; */
	background: #fff;
	padding: 10px 16px 10px 14px;
	border-bottom: 1px solid #eee;
	/* margin-bottom: 1rem; */
}

@media screen and (max-width: 600px) {
	.actions-container {
		margin-top: 0px !important;
	}
}

.quickv__container {
	height: 88vh !important;
	overflow-y: scroll !important;
}

.active-drag {
	background: lightgreen !important;
}

.p-0 {
	padding: 0 !important;
}

button.bp3-intent-success {
	font-weight: 600 !important;
	cursor: pointer !important;
	padding: 3px 10px 3px 10px !important;
	border-radius: 2px !important;
	transition: all 0.3s ease !important;
	color: #fff !important;
	background: #08a742 !important;
	border: 1px solid #019c3b !important;
	box-shadow: none !important;
	font-family: inherit !important;
	outline: none !important;
}

button.bp3-intent-success:active {
	outline: none !important;
}

button.bp3-intent-success:hover {
	background: #00bd45 !important;
	border: 1px solid #03bd45 !important;
}

button {
	outline: none !important;
}

button.bp3-intent-primary {
	font-weight: 600 !important;
	cursor: pointer !important;
	padding: 3px 10px 3px 10px !important;
	border-radius: 2px !important;
	transition: all 0.3s ease !important;
	color: #fff !important;
	background: #137cbd !important;
	border: 1px solid #137cbd !important;
	box-shadow: none !important;
	font-family: inherit !important;
	outline: none !important;
}

button.bp3-intent-primary:active {
	outline: none !important;
}

button.bp3-intent-primary:hover {
	background: #106ba3 !important;
	border: 1px solid #106ba3 !important;
}

button {
	outline: none !important;
}

.custom-select:disabled {
	color: #adb5bd;
	background-color: #f8f9fa;
}

.totalcalc {
	background: #f5f7f9;
	font-size: 1.2rem;
	font-weight: 600;
}

.log-container {
	height: 300px;
	border: 1px solid #eee;
	width: 100%;
	padding: 10px;
	overflow-y: auto;
}

.log-container ul {
	padding-left: 0;
	margin-left: 0;
}

.log-container ul li {
	border-left: 2px solid green;
	padding-left: 10px;
	padding-bottom: 4px;
	margin-bottom: 2px;
}

.log-container ul li:hover {
	background-color: #f9f9f9;
	cursor: default;
}

.log-container ul li span {
	color: rgb(133, 133, 133);
	font-size: 12px;
}

.log-record h4 {
	margin-bottom: 0;
}

.log-record small {
	opacity: 0.7;
}

.bp3-popover-content-dates {
	max-width: none !important;
	background: #fff;
}

.ql-container {
	font-size: 16px !important;
}

@media (min-width: 800px) {
	.ql-container {
		font-size: 14px !important;
	}
}

.ql-toolbar.ql-snow {
	z-index: 10;
}

.ql-toolbar.ql-snow,
.ql-container.ql-snow {
	border-color: #e0e5ed !important;
}

.ql-toolbar.ql-snow {
	border-radius: 2px 2px 0px 0px;
}

.ql-editor:focus {
	outline: unset !important;
}

.ql-container.ql-snow {
	border-radius: 0px 0px 2px 2px;
}

.nostyleul {
	margin-left: 0;
	padding-left: 0;
	list-style: none;
}

.react-select__menu {
	z-index: 100 !important;
}

.modal-merge .modal-title {
	width: 100% !important;
}

.bg-yellow {
	background-color: #ffbf2e;
}

.bg-white {
	background: #fff;
}

.alice-carousel {
	box-sizing: border-box;
	position: relative;
	width: 100%;
	margin: auto;
}

.alice-carousel__wrapper {
	position: relative;
	width: 100%;
	height: auto;
	box-sizing: border-box;
	overflow: hidden;
}

.alice-carousel__stage {
	position: relative;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	white-space: nowrap;
	box-sizing: border-box;
}

.alice-carousel__stage-item {
	width: 100%;
	height: 100%;
	position: relative;
	display: inline-block;
	vertical-align: top;
	white-space: normal;
	line-height: 0;
}

.alice-carousel__stage-item * {
	line-height: initial;
}

.alice-carousel__stage-item.__cloned {
	opacity: 0;
	visibility: hidden;
}

.alice-carousel__stage:after {
	content: '.';
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

.alice-carousel__prev-btn,
.alice-carousel__next-btn {
	display: inline-block;
	padding: 15px 10px;
	box-sizing: border-box;
}

.alice-carousel__prev-btn [data-area]::after,
.alice-carousel__next-btn [data-area]::after {
	content: attr(data-area);
	position: relative;
	text-transform: capitalize;
}

.alice-carousel__prev-btn {
	/* modificado */
	position: absolute;
	left: 0;
	top: 40%;
}

.alice-carousel__prev-btn-item,
.alice-carousel__next-btn-item {
	display: inline-block;
	color: #465798;
	cursor: pointer;
}

.alice-carousel__prev-btn-item:hover,
.alice-carousel__next-btn-item:hover {
	color: darkred;
}

.alice-carousel__prev-btn-item.__inactive,
.alice-carousel__next-btn-item.__inactive {
	opacity: 0.4;
}

.alice-carousel__play-btn {
	display: inline-block;
	position: absolute;
	top: 30px;
	left: 20px;
}

.alice-carousel__play-btn:hover {
	cursor: pointer;
}

.alice-carousel__play-btn-wrapper {
	position: relative;
	background-color: #fff;
	padding: 10px;
	width: 32px;
	height: 32px;
	border-radius: 50%;
}

.alice-carousel__play-btn-item {
	cursor: pointer;
	position: absolute;
	background: transparent;
	width: 32px;
	height: 32px;
	outline: none;
	border: 0;
}

.alice-carousel__play-btn-item::before,
.alice-carousel__play-btn-item::after {
	content: '';
	width: 0;
	height: 0;
	display: block;
	position: absolute;
	border-style: solid;
	border-color: transparent;
	border-width: 8px 0 8px 15px;
	border-left-color: #465798;
	transition: all 0.3s linear;
}

.alice-carousel__play-btn-item::before {
	height: 14px;
	left: 5px;
}

.alice-carousel__play-btn-item::after {
	left: 18px;
	top: 7px;
}

.alice-carousel__play-btn-item.__pause::before,
.alice-carousel__play-btn-item.__pause::after {
	border-width: 0 0 0 10px;
	height: 30px;
}

.alice-carousel__play-btn-item.__pause::after {
	left: 18px;
	top: 0;
}

.alice-carousel__dots {
	margin: 30px 0 5px;
	text-align: center;
	list-style: none;
	padding: 0;
}

.alice-carousel__dots-item {
	display: inline-block;
	width: 8px;
	height: 8px;
	cursor: pointer;
	border-radius: 50%;
	background-color: #e0e4fb;
}

.alice-carousel__dots-item:not(:last-child) {
	margin-right: 15px;
}

.alice-carousel__dots-item:hover,
.alice-carousel__dots-item.__active {
	background-color: #6e7ebc;
}

.alice-carousel__slide-info {
	display: inline-block;
	position: absolute;
	right: 20px;
	top: 20px;
	padding: 5px 10px;
	color: #465798;
	border-radius: 5px;
	background-color: rgba(224, 228, 251, 0.6);
}

.alice-carousel__slide-info-item {
	line-height: 0;
	vertical-align: middle;
}

.alice-carousel .animated {
	animation-fill-mode: both;
}

.alice-carousel .animated-out {
	z-index: 1;
}

.alice-carousel .fadeOut {
	animation-name: fadeOut;
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		visibility: hidden;
	}
}

.alice-carousel__prev-btn {
	/* modificado */
	position: absolute;
	right: 0;
	top: 40%;
	display: none;
	/* background: red; */
	text-align: right;
}

.alice-carousel__next-btn {
	/* modificado */
	position: absolute;
	right: 0;
	top: 40%;
	display: none;
	/* background: red; */
	text-align: right;
}

.flex-end {
	display: flex;
	justify-content: flex-end;
}

.separator {
	display: inline-block;
	padding: 0 5px;
	opacity: 0.5;
}

.title-separator {
	border-bottom: 1px solid #eee;
	padding: 10px 0;
	border-top: 1px solid #eee;
	text-align: center;
	text-transform: uppercase;
}

.bp3-portal {
	z-index: 5000;
}

.datepicker input {
	box-shadow: none;
	font-size: 15px;
	padding: 0;
}

.datepicker input:focus {
	font-size: 15px;
	padding: 0;
	border-radius: 0px;
}

.important_dates li {
	display: flex;
	padding: 0;
	flex: 1;
	justify-content: space-between;
}

.important_dates li div:first-child {
	flex: 1;
}

.important_dates li div:last-child {
	flex: 2;
}

/* -------------------------------- 
  
  Modules - reusable parts of our design
  
  -------------------------------- */

.cd-container {
	/* this class is used to give a max-width to the element it is applied to, and center it horizontally when it reaches that max-width */
	width: 100%;
	margin: 0 auto;
}

.cd-container::after {
	/* clearfix */
	content: '';
	display: table;
	clear: both;
}

/* -------------------------------- 
  Main components 
  -------------------------------- */

#cd-timeline {
	position: relative;
	margin-bottom: 5em;
}

#cd-timeline::before {
	content: '';
	position: absolute;
	top: 0;
	left: 18px;
	height: 100%;
	width: 4px;
	background: #d7e4ed;
}

.cd-timeline-block {
	position: relative;
	margin: 2em 0;
}

.cd-timeline-block::after {
	clear: both;
	content: '';
	display: table;
}

.cd-timeline-block:first-child {
	margin-top: 0;
}

.cd-timeline-block:last-child {
	margin-bottom: 0;
}

.cd-timeline-img {
	position: absolute;
	top: 0;
	left: 0;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	box-shadow: 0 0 0 4px #ffffff, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-size: 24px;
}

.cd-timeline-img img {
	display: block;
	width: 24px;
	height: 24px;
	position: relative;
	/* left: 50%;
    top: 50%;
    margin-left: -12px;
    margin-top: -12px; */
}

.cd-timeline-img.cd-phone {
	background: #75ce66;
}

.cd-timeline-img.cd-send {
	background: #c03b44;
}

.cd-timeline-img.cd-users {
	background: #f0ca45;
}

.cd-timeline-img.cd-clock {
	background: #e825fd;
}

.cd-timeline-img.cd-flag {
	background: #81cecb;
}

.cd-timeline-img.cd-sun {
	background: brown;
}

.cd-timeline-img.cd-video {
	background: #3caea3;
}

.cd-timeline-img.cd-whatsapp {
	background: #0d70bf;
}

.cd-timeline-content {
	position: relative;
	margin-left: 60px;
	background: #ffffff;
	border-radius: 0.25em;
	padding: 1em;
	box-shadow: 0 3px 0 #d7e4ed;
}

.cd-is_comment {
	background: rgb(173, 173, 173);
}

.cd-is_log {
	color: #86cfcd !important;
	width: 20px !important;
	height: 20px !important;
	font-size: 8px !important;
	left: 10px !important;
	background: #86cfcd !important;
	top: 6px;
}

.cd-timeline-content.is-comment {
	background: #fffcdc !important;
}

.cd-timeline-content.is-comment p {
	margin: 0;
}

.cd-timeline-content::after {
	clear: both;
	content: '';
	display: table;
}

.cd-timeline-content h2 {
	color: #303e49;
}

.cd-timeline-content p,
.cd-timeline-content .cd-read-more,
.cd-timeline-content .cd-date {
	font-size: 13px;
	font-size: 0.8125rem;
}

.cd-timeline-content .cd-read-more,
.cd-timeline-content .cd-date {
	display: inline-block;
}

.cd-timeline-content p {
	margin: 0;
	line-height: 1.6;
}

.cd-timeline-content .cd-read-more {
	float: right;
	padding: 0.8em 1em;
	background: #acb7c0;
	color: #ffffff;
	border-radius: 0.25em;
}

.no-touch .cd-timeline-content .cd-read-more:hover {
	background-color: #bac4cb;
}

.cd-timeline-content .cd-date {
	float: left;
	padding: 0.8em 0;
	opacity: 0.7;
}

.cd-timeline-content::before {
	content: '';
	position: absolute;
	top: 16px;
	right: 100%;
	height: 0;
	width: 0;
	border: 7px solid transparent;
	border-right: 7px solid #ffffff;
}

.cd-timeline-content.yellow-bg::before {
	border-right: 7px solid #fffcdc !important;
}

.cd-content-is_log {
	background-color: transparent !important;
	box-shadow: unset;
	padding: 0 1em;
}

.cd-timeline-content .md-checkbox label {
	font-family: inherit;
	font-weight: 600;
	color: inherit;
	font-size: 1.15rem;
}

@media only screen and (min-width: 768px) {
	.cd-timeline-content h2 {
		font-size: 20px;
		font-size: 1.25rem;
	}

	.cd-timeline-content p {
		font-size: 16px;
		font-size: 1rem;
	}

	.cd-timeline-content .cd-read-more,
	.cd-timeline-content .cd-date {
		font-size: 14px;
		font-size: 0.875rem;
	}
}

.cd-timeline-options {
	position: absolute;
	right: 1rem;
	color: #a5a5a5;
	z-index: 10;
	font-size: 1.4rem;
	top: 0px;
	opacity: 0.7;
}

.expandable-text {
	height: 200px;
	border-radius: 0px 0px 4px 4px;
}

.expandable-text:focus {
	border-color: #eee;
	box-shadow: none !important;
	border-top: 0px;
}

/* react calendar */

.react-calendar {
	border: 1px solid #ddd !important;
	border-radius: 4px !important;
}

.react-calendar__tile--active {
	background: #467fcf !important;
	color: #fff !important;
	background-color: #467fcf !important;
	background-image: linear-gradient(-38deg, #2a87ff 0, #7aaaec 100%) !important;
	border-radius: 4px !important;
}

.not-found-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 2rem;
}

.not-found-container img {
	max-height: 350px;
}

.not-found-container h4 {
	margin-top: 1.5rem;
}

.tabv2 .bp3-tab-panel {
	margin-top: 0;
	border-top: none;
}

.tabv2 .bp3-tab-list {
	border-bottom: 2px solid #eee;
	background: #fff;
}

@media (max-width: 600px) {
	.tabv2 .bp3-tab-list {
		overflow-x: scroll !important;
		overflow-y: hidden;
	}
}

.tabv2 .bp3-tab {
	font-size: 1rem;
	font-weight: 500;
	padding: 8px 16px;
}

.tabv2 .bp3-tab-indicator-wrapper .bp3-tab-indicator {
	background-color: #5f90d5;
}

.tabv2 .bp3-tab[aria-selected='true'],
.tabv2 .bp3-tab:not([aria-disabled='true']):hover {
	color: #5f90d5;
}

.tabv2 .bp3-tab-indicator-wrapper {
	top: 2px;
}

.spinner-white .bp3-spinner .bp3-spinner-head {
	stroke: #fff;
}

.card-body .text-yellow.text-right {
	color: transparent !important;
	font-size: 7px !important;
}

.light-p {
	color: rgba(38, 41, 44, 0.64);
	font-size: 15px;
	margin: 0px;
}

.plus-btn {
	font-size: 12px;
	margin-right: 5px;
}

/* Class que modifica el height de un Draggable y permite que se pueda hacer el Drop*/

.draggable-fix-height img {
	max-height: 20px;
	opacity: 0.7;
	margin-bottom: 10px;
}

.draggable-fix-height {
	border: dashed 1px #ccc;
	width: 80px;
	height: 80px;
	left: calc(50% - 40px);
	background-color: #e4feff;
	padding: 10px;
	font-size: 10px;
}

.pointer {
	cursor: pointer;
}

.pointer-link:hover {
	color: #467fcf !important;
}

@media screen and (max-width: 600),
screen and (max-height: 700px) {
	.sticky {
		position: inherit !important;
	}
}

.divider-p {
	position: relative;
	width: 100%;
	text-transform: uppercase;
	font-size: 13px;
	color: rgb(116, 118, 120);
}

.divider-p span {
	z-index: 1;
	position: relative;
	background: rgb(255, 255, 255);
	padding: 0px 8px 0px 0px;
}

.divider-p::after {
	position: absolute;
	content: '';
	top: 9px;
	right: 0px;
	width: 100%;
	border-bottom: 1px solid rgb(229, 229, 229);
}

.goal-details-field:hover {
	background: #eee;
}

.goal-details-field {
	display: flex;
	height: 32px;
	-webkit-box-align: center;
	align-items: center;
	box-sizing: border-box;
	padding: 6px 16px;
	margin: 0px -15px;
}

.goal-details-field .title {
	color: rgb(116, 118, 120);
	display: inline-block;
	width: 136px;
	padding-right: 8px;
	font-size: 13px;
	font-weight: normal;
	text-align: right;
}

.goal-details-field .value {
	color: rgb(38, 41, 44);
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	padding-right: 8px;
	font-weight: normal;
	font-size: 15px;
}

.actions-menu-container {
	background: #fff;
	/* margin-top: -2rem; */
	border-bottom: 1px solid #eee;
	margin-bottom: 2rem;
}

.actions-menu-container ul {
	border-bottom: 0 !important;
}

.align-between {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

a .tagSmall {
	text-decoration: none;
	color: #182026;
}

.tagSmall {
	background: #eee;
	padding: 2px 4px;
	border-radius: 4px;
}

/*******************************
*       SHARE MODAL
********************************/
.share-modal {
	display: flex;
	justify-content: center;
	flex-direction: column;
	margin: 0;
	padding: 0;
	list-style: none;
}

.share-modal ul {
	padding: 0;
}

.share-modal a {
	display: inline-block;
	padding: 8px 15px;
	color: #fff !important;
	margin-bottom: 10px;
	color: #fff;
	width: 100%;
}

.share-modal a.facebook {
	background-color: #3b5998;
}

.share-modal a.twitter {
	background-color: #55acee;
}

.share-modal a.mail {
	background-color: #7d7d7d;
}

.share-modal a.whatsapp {
	background-color: #25d366;
}

.share-modal a {
	color: #fff !important;
}

.out-of-stock {
	background: #ff000029 !important;
}

ul.react-select-ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

ul.react-select-ul li {
	display: inline-block;
	padding-right: 10px;
}

ul.react-select-ul li i,
ul.react-select-ul li img,
ul.react-select-ul li span {
	padding-right: 5px;
	opacity: 0.5;
}

@media (min-width: 576px) {
	.modal-xxl {
		max-width: 95% !important;
	}
}

@media (min-width: 992px) {
	.modal-xxl {
		max-width: 80vw;
	}
}

@media (min-width: 992px) {
	.modal-xl {
		max-width: 800px !important;
	}
}

@media (min-width: 1200px) {
	.modal-xl {
		max-width: 1140px !important;
	}
}

.FormGroup {
	margin: 0 0 20px;
	padding: 0;
	border-style: none;
	background-color: #fff;
	will-change: opacity, transform;
	border-radius: 4px;
	border: 1px solid #e0e0e0;
}

.FormRow {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	margin-left: 15px;
	border-top: 1px solid #fff;
}

.StripeElement--webkit-autofill {
	background: transparent !important;
}

.StripeElement {
	width: 100%;
	padding: 11px 15px 11px 0;
}

/* ANIMATIONS FOR LINKEA */
@keyframes linkea_bounce {

	from,
	10%,
	26.5%,
	40%,
	to {
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		transform: translate3d(0, 0, 0);
	}

	20%,
	21.5% {
		animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
		transform: translate3d(0, -10px, 0);
	}

	35% {
		animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
		transform: translate3d(0, -10px, 0);
	}

	42.5% {
		transform: translate3d(0, -2px, 0);
	}
}

.linkea_bounce {
	animation-name: linkea_bounce;
	transform-origin: center bottom;

	animation-duration: 4s;
	animation-fill-mode: both;
	animation-iteration-count: infinite;
}

@keyframes linkea_jello {

	from,
	5.55%,
	50% {
		transform: translate3d(0, 0, 0);
	}

	11.1% {
		transform: skewX(-3.125deg) skewY(-3.125deg);
	}

	16.65% {
		transform: skewX(3.125deg) skewY(3.125deg);
	}

	22.2% {
		transform: skewX(-3.125deg) skewY(-3.125deg);
	}

	27.75% {
		transform: skewX(1.5625deg) skewY(1.5625deg);
	}

	33.3% {
		transform: skewX(-0.78125deg) skewY(-0.78125deg);
	}

	38.85% {
		transform: skewX(0.390625deg) skewY(0.390625deg);
	}

	44.4% {
		transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
	}
}

.linkea_jello {
	animation-name: linkea_jello;
	transform-origin: center;
	animation-duration: 4s;
	animation-fill-mode: both;
	animation-iteration-count: infinite;
}

@keyframes linkea_pulse {
	from {
		transform: scale3d(1, 1, 1);
	}

	25% {
		transform: scale3d(1.05, 1.05, 1.05);
	}

	50% {
		transform: scale3d(1, 1, 1);
	}
}

.linkea_pulse {
	animation-name: linkea_pulse;
	transform-origin: center;
	animation-duration: 4s;
	animation-fill-mode: both;
	animation-iteration-count: infinite;
}

@keyframes linkea_shake {

	from,
	to {
		transform: translate3d(0, 0, 0);
	}

	5%,
	15%,
	25%,
	35%,
	45% {
		transform: translate3d(-5px, 0, 0);
	}

	10%,
	20%,
	30%,
	40% {
		transform: translate3d(5px, 0, 0);
	}

	50% {
		transform: translate3d(0, 0, 0);
	}
}

.linkea_shake {
	animation-name: linkea_shake;
	transform-origin: center;
	animation-duration: 4s;
	animation-fill-mode: both;
	animation-iteration-count: infinite;
}

@keyframes linkea_tada {
	from {
		transform: scale3d(1, 1, 1);
	}

	5%,
	10% {
		transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -1.5deg);
	}

	15%,
	25%,
	35%,
	45% {
		transform: scale3d(1.05, 1.05, 1.05) rotate3d(0, 0, 1, 1.5deg);
	}

	20%,
	30%,
	40% {
		transform: scale3d(1.05, 1.05, 1.05) rotate3d(0, 0, 1, -1.5deg);
	}

	50% {
		transform: scale3d(1, 1, 1);
	}
}

.linkea_tada {
	animation-name: linkea_tada;
	transform-origin: center;
	animation-duration: 4s;
	animation-fill-mode: both;
	animation-iteration-count: infinite;
}

@keyframes linkea_wobble {
	from {
		transform: translate3d(0, 0, 0);
	}

	7.5% {
		transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -5deg);
	}

	15% {
		transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 3deg);
	}

	22.5% {
		transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -3deg);
	}

	30% {
		transform: translate3d(2%, 0, 0) rotate3d(0, 0, 1, 2deg);
	}

	37.5% {
		transform: translate3d(-1%, 0, 0) rotate3d(0, 0, 1, -1deg);
	}

	50% {
		transform: translate3d(0, 0, 0);
	}
}

.linkea_wobble {
	animation-name: linkea_wobble;
	animation-duration: 4s;
	animation-fill-mode: both;
	animation-iteration-count: infinite;
}

.yellow-hover:hover {
	background: lightyellow;
}

.section__title {
	background: lightyellow;
	margin-top: 10px;
	margin-bottom: 10px;
	padding: 10px 5px;
	text-align: center;
	font-size: 16px;
	font-weight: 800;
}

.bp3-slider-handle .bp3-slider-label {
	display: none;
}

.bp3-toast-container.bp3-toast-container-inline {
	position: fixed;
}

@media print {
	.show-print {
		display: block !important;
	}

	.no-print,
	.no-print * {
		display: none !important;
	}
}

.td_size_xs {
	min-width: 50px;
}

.td_size_sm {
	min-width: 100px;
}

.td_size_md {
	min-width: 150px;
}

.td_size_lg {
	min-width: 200px;
}

.td_size_xl {
	min-width: 250px;
}

.td_size_xxl {
	min-width: 300px;
}

th.sticky,
td.sticky {
	text-align: left !important;
	background-color: #fff !important;
	left: 0;
	position: -webkit-sticky;
	position: sticky;
	z-index: 10;
	border-right: 1px solid #eee;
}

tr.sticky:hover,
th.sticky:hover,
td.sticky:hover {
	background-color: #e9eef1 !important;
}

modal-header .btn-close {
	padding: 0.5rem 0.5rem;
	margin: -0.5rem -0.5rem -0.5rem auto;
}

.btn-close:hover {
	color: #000;
	text-decoration: none;
	opacity: 0.75;
}

.btn-close {
	box-sizing: content-box;
	width: 1em;
	height: 1em;
	padding: 0.25em 0.25em;
	color: #000;
	background: transparent url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e) center/1em auto no-repeat;
	border: 0;
	border-radius: 0.25rem;
	opacity: 0.5;
}

.cursor-pointer {
	cursor: pointer;
}

@media only screen and (max-width: 480px) {
	@-ms-viewport {
		width: 320px;
	}

	@viewport {
		width: 320px;
	}
}

.hide_on_mobile {
	display: none !important;
}

@media only screen and (min-width: 480px) {
	.hide_on_mobile {
		display: table-row !important;
	}
}

[owa] .mj-column-per-100 {
	width: 100% !important;
}

[owa] .mj-column-per-50 {
	width: 50% !important;
}

[owa] .mj-column-per-33 {
	width: 33.333333333333336% !important;
}

@media only screen and (min-width: 480px) {
	.mj-column-per-100 {
		width: 100% !important;
	}

	.mj-column-per-40 {
		width: 40% !important;
	}

	.mj-column-per-60 {
		width: 60% !important;
	}
}

.bp3-list {
	width: 100%;
	overflow-x: scroll;
	height: 100%;
	min-height: 59px;
}
.d-flex-sb {
	display: flex;
	justify-content: space-between;
}
.d-flex-sb-center {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.d-flex-jc-center {
	display: flex;
    align-items: center;
    justify-content: center;
}
.d-flex-right {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.icon_container {
	width: 20px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
}
.react-select__indicator-separator {
	display: none!important;
}
/* TEMPLATE MODIFICATION */
.modal-content {
	border-radius: 0.5rem;
    outline: 0;
    border: 0;
    box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0 20px 25px -5px, rgb(0 0 0 / 10%) 0px 8px 10px -6px;
}
.form-control:focus {
    border-color: #1991eb;
    box-shadow: none;
}
.card {
	border-radius: 8px;
	box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0.1%) 0 1px 3px 0px, rgb(0 0 0 / 0.1) 0px 1px 2px -1px;
}
.tag-green {
    background-color: #42906F;
    color: #fff;
}
.metrics a:hover {
	text-decoration: none;
}
.metrics .card-body {
	position: relative;
}
.metrics .metric_icon {
	width: 32px;
	height: 32px;
	background: red;
	border-radius: 50%;
	position: absolute;
	right: 1.5rem;
	top: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
}
.metrics .deals {
	background: #eff7e2;
	color: #42906F;
}
.metrics .properties {
	background: #f8efe2;
	color: #E08734;
}
.metrics .prospects {
	background: #d6f5ff;
	color: #348ee0;
}
.metrics .views {
	background: #e1e2f6;
	color: #676ef8;
}
.metrics .metric_number {
	color: #1C434C;
}
.metrics label {
	color: #7A848D;
	cursor: pointer;
}
.bp3-popover {
	box-shadow: none;
    transform: scale(1);
    border-radius: 3px;
    display: inline-block;
    z-index: 20;
    border: 1px solid #eee;
}
.bp3-popover .bp3-popover-arrow::before {
    box-shadow: none;
}
.delete-filters {
	transition: all 0.3s;
	color: #ac1b1a;
	text-decoration: underline;
	border-radius: 50%;
	text-align: center;
	font-size: 13px;
	cursor: pointer;

}
.delete-filters:hover {
	color: red;
}
.title-text-divider {
	text-align: center;
    font-size: 1.2rem;
    font-weight: 600;
    text-transform: uppercase;
    background: #daecea;
    border-radius: 5px;
    margin: 20px 0px;
    padding: 7px;
}
.float-right {
	text-align: right;
}
.flex-end {
	justify-content: flex-end;
}