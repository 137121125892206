/* CONTACTS */
.ae-import {
    background: url(../svg/import.svg);
    width: 20px;
    height: 20px;
    margin-right: .5rem;
    display: inline-block;
}
.ae-export {
    background: url(../svg/import.svg);
    width: 20px;
    height: 20px;
    margin-right: .5rem;
    display: inline-block;
    transform: rotate(180deg);
}
.ae-actions {
    background: url(../svg/three-dots-more-indicator.svg);
    width: 20px;
    height: 20px;
    margin-right: .5rem;
    display: inline-block;
}
/* PROPERTIES */
.card__property-ammenities-bed {
    background: url(../svg/bed.svg);
    width: 15px;
    height: 15px;
    display: inline-block;
    background-position-y: 2px;
}

.card__property-ammenities-bath {
    background: url(../svg/bathtub.svg);
    width: 15px;
    height: 15px;
    display: inline-block;
}

.card__property-ammenities-parking {
    background: url(../svg/car.svg);
    width: 20px;
    height: 20px;
    display: inline-block;
    background-position-y: 5px;
}

.card__property-ammenities-size {
    background: url(../svg/format.svg);
    width: 15px;
    height: 15px;
    opacity: .7;
    background-position-y: 2px;
    display: inline-block;
}
